import { DateTime } from 'luxon'
import { BucketName } from './types'

type BucketConfig = {
  displayName: string
  startDate: (date: string) => DateTime
}

export const BUCKETS_CONFIG_MAP: Record<BucketName, BucketConfig> = {
  'oso-sounds': {
    displayName: 'PRODUCTION',
    startDate: (date) => DateTime.fromISO(date).plus({ hour: 12 }),
  },
  'oso-resp-sounds': {
    displayName: 'PARIS-APHP - Service Pneumo',
    startDate: (date) => DateTime.fromISO(date).startOf('day'),
  },
  'cp-children-sounds': {
    displayName: 'HOPITAL-DES-ARMEES-BREST - Enfants Paralysés Cérébraux',
    startDate: (date) => DateTime.fromISO(date).startOf('day'),
  },
}
