import { DateTime } from 'luxon'

type SingularTimeUnit = 'second' | 'minute' | 'hour' | 'day'
type PluralTimeUnit = `${SingularTimeUnit}s`
type TimeUnit = SingularTimeUnit | PluralTimeUnit

const HOLIDAYS = [
  '2024-01-01',
  '2024-04-01',
  '2024-05-01',
  '2024-05-08',
  '2024-05-09',
  '2024-05-20',
  '2024-07-14',
  '2024-08-15',
  '2024-11-01',
  '2024-11-11',
  '2024-12-25',
  '2025-01-01',
  '2025-04-21',
  '2025-05-01',
  '2025-05-08',
  '2025-05-29',
  '2025-06-09',
  '2025-07-14',
  '2025-08-15',
  '2025-11-01',
  '2025-11-11',
  '2025-12-25',
]

export function ms(value: number, unit: TimeUnit) {
  switch (unit) {
    case 'second':
    case 'seconds':
      return value * 1000
    case 'minute':
    case 'minutes':
      return value * 60 * 1000
    case 'hour':
    case 'hours':
      return value * 60 * 60 * 1000
    case 'day':
    case 'days':
      return value * 24 * 60 * 60 * 1000
  }
}

export function ms2sec(ms: number) {
  return ms / 1000
}

export function sec2ms(seconds: number) {
  return seconds * 1000
}

/** milliseconds to YYYY-MM-DD conversion */
export function tsToDate(ts: number) {
  return new Date(ts).toISOString().slice(0, 10)
}

export function formatDate(dateTime: DateTime) {
  return dateTime
    .setLocale('fr')
    .setZone('Europe/Paris')
    .toLocaleString(DateTime.DATE_SHORT)
}

export function formatMsDate(ts: number) {
  return formatDate(DateTime.fromMillis(ts))
}

export function formatIsoDate(isoDate: string) {
  return formatDate(DateTime.fromISO(isoDate))
}

export function formatTime(dateTime: DateTime) {
  return dateTime
    .setZone('Europe/Paris')
    .toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function formatIsoTime(isoDate: string) {
  return formatTime(DateTime.fromISO(isoDate))
}

export function formatMsTime(milliseconds: number) {
  return formatTime(DateTime.fromMillis(milliseconds))
}

export const bucketShiftHour = 12 // date changes at noon

export function noonDate(dateTime: DateTime) {
  const parisDateTime = dateTime.setZone('Europe/Paris')
  const day = parisDateTime.hour < bucketShiftHour ? 1 : 0
  return parisDateTime.minus({ day }).toISODate()
}

export function formCurrentDateTime() {
  return DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm")
}

export const isHoliday = (date: DateTime) => {
  const today = date.toFormat('yyyy-MM-dd')
  return HOLIDAYS.includes(today)
}

export const isWeekend = (date: DateTime) => {
  return date.isWeekend
}
