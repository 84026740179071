import { MetricKey } from '../../types/victoria'

export const metricPrefixes = [
  'aws_layer1_',
  'aws_dionysos_',
  'aws_alertor_',
  'aws_janus_',
]

export function metricDisplayName(metric: MetricKey) {
  if (metric in defaultMetrics) return defaultMetrics[metric].label

  return metricPrefixes.reduce((acc, prefix) => acc.replace(prefix, ''), metric)
}

export type MetricsConfig = Record<MetricKey, { label: string; color: string }>

// These names must match the metrics names defined in Victoria Metrics
export const defaultMetrics: MetricsConfig = {
  aws_layer1_Parole: {
    label: 'Parole',
    color: 'lime',
  },
  aws_layer1_Ronflement: {
    label: 'Ronflement',
    color: 'yellow',
  },
  aws_layer1_Toux: {
    label: 'Toux',
    color: 'red',
  },
  aws_layer1_AS: {
    label: 'AS',
    color: 'white',
  },
  'aws_layer1_Eau-toilettes': {
    label: 'Eau, toilettes',
    color: 'blue',
  },
  'aws_layer1_Bruit de pas': {
    label: 'Bruit de pas',
    color: 'fuchsia',
  },
  aws_layer1_Porte: {
    label: 'Porte',
    color: 'tomato',
  },
  aws_layer1_Brouhaha: {
    label: 'Brouhaha',
    color: 'gray',
  },
  aws_layer1_Respiration: {
    label: 'Respiration',
    color: 'olivedrab',
  },
  'aws_layer1_Lit draps': {
    label: 'Lit draps',
    color: 'turquoise',
  },
  'aws_layer1_Deux personnes ou plus': {
    label: '2 pers.',
    color: 'mediumspringgreen',
  },
  aws_layer1_Choc: {
    label: 'Choc',
    color: 'orange',
  },
  aws_layer1_Tambourinage: {
    label: 'Tambourinage',
    color: 'lightblue',
  },
  aws_layer1_Cri: {
    label: 'Cri',
    color: 'deeppink',
  },
  aws_layer1_Gemissement: {
    label: 'Gémissement',
    color: 'pink',
  },
  aws_layer1_Vomissement: {
    label: 'Vomissement',
    color: 'forestgreen',
  },
  'aws_layer1_Difficulte respiratoire': {
    label: 'Difficulté respiratoire',
    color: 'mediumslateblue',
  },
  aws_layer1_Television: {
    label: 'Télévision',
    color: 'LightGray',
  },
}

export const JANUS_METRICS: MetricsConfig = {
  aws_janus_Present: {
    label: 'Résident présent',
    color: '#9a0e01',
  },
  aws_janus_Absent: {
    label: 'Résident absent',
    color: '#d8e335',
  },
  aws_janus_Entree: {
    label: 'Entrée',
    color: '#3b2f7f',
  },
  aws_janus_Sortie: {
    label: 'Sortie',
    color: '#d02f04',
  },
  aws_janus_Appel_confort: {
    label: 'Appel confort',
    color: '#466be3',
  },
  aws_janus_Appel_urgent: {
    label: 'Appel urgent',
    color: '#1ad1d2',
  },
  aws_janus_Chute: {
    label: 'Chute',
    color: '#e3440a',
  },
  aws_janus_Cris: {
    label: 'Cris',
    color: '#edcf39',
  },
  aws_janus_Crise_agitation: {
    label: 'Crise_agitation',
    color: '#30123b',
  },
  aws_janus_Deambulation: {
    label: 'Deambulation',
    color: '#434eba',
  },
  aws_janus_Besoin_assistance: {
    label: 'Besoin assistance',
    color: '#a4fc3b',
  },
  aws_janus_Monologue: {
    label: 'Monologue',
    color: '#f26014',
  },
  aws_janus_Vomissement: {
    label: 'Nausee',
    color: '#7a0402',
  },
  aws_janus_Silence: {
    label: 'Silence',
    color: '#28bbeb',
  },
  aws_janus_Sleeping: {
    label: 'Sleeping',
    color: '#b91e01',
  },
  aws_janus_Tambourinages_repetes: {
    label: 'Tambourinage',
    color: '#1ae4b6',
  },
  aws_janus_AS_Presence: {
    label: 'Présence AS',
    color: '#4584f9',
  },
  aws_janus_Voisinage_AS: {
    label: 'Voisinage AS',
    color: '#fa7d20',
  },
}
