export function plural(
  counter: number | unknown[],
  texts: string | string[],
  masculine: boolean,
  uppercase = true,
) {
  if (!Array.isArray(texts)) texts = [texts]
  if (Array.isArray(counter)) counter = counter.length
  const prefix =
    counter === 0
      ? `${uppercase ? 'A' : 'a'}ucun${masculine ? '' : 'e'}`
      : `${counter}`
  const s = counter > 1 ? 's' : ''
  return `${prefix} ${texts.map((text) => `${text}${s}`).join(' ')}`
}
