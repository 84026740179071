import { BUCKETS_CONFIG_MAP } from 'common/bucketConfig'
import { BucketName } from 'common/types'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { formatMsTime, ms } from 'shared/utils/time'
import { onError } from 'shared/utils/web/error'
import { S3Sound, fetchData, formatPrefix } from 'shared/utils/web/fetchData'
import { MetricsConfig } from 'shared/utils/web/metrics'
import { Buckets } from './Buckets'
import { Player } from './Player'
import { useS3Context } from './S3Provider'
import { TimeMarks } from './TimeMarks'
import { Button } from './components/Button'
import { usePredictions } from './hooks/usePredictions'

// An ISO date clamped to 10 minutes
export type Prefix = string

export type GraphCache = Map<Prefix, { counts: number[]; sums: number[] }>

// undefined = prefix loading is not yet started
// null = prefix loading is in progress
export type PrefixData =
  | {
      data: S3Sound[]
      start: number
      end: number
    }
  | undefined

export type PrefixDataMap = Record<Prefix, PrefixData>

export const BUCKET_DURATION = ms(10, 'minutes')
export const SOUND_DURATION = ms(9.6, 'seconds')

type PreloadType = 'all' | 'night'

const PRELOAD_SHIFTS: Record<
  PreloadType,
  { startIndex: number; endIndex: number }
> = {
  all: {
    startIndex: 0,
    endIndex: ms(24, 'hours') / BUCKET_DURATION,
  },
  night: {
    startIndex: ms(8, 'hours') / BUCKET_DURATION, // Start at 8PM but with 12 hours offset
    endIndex: ms(20, 'hours') / BUCKET_DURATION, // End at 8AM but with 12 hours offset
  },
}

function defaultPrefixDataMap(bucketPrefixes: string[]) {
  return bucketPrefixes.reduce(
    (acc, prefix) => ({ ...acc, [prefix]: undefined }),
    {},
  )
}

// shall work only if the pathname end with /(date)/(timestamp)
function getRoundedPrefixFromURL() {
  const pathname = window.location.pathname

  // remove first '/' and split path
  const pathParts = pathname.substring(1).split('/')
  // remove and get last item : timestamp
  const timestamp = pathParts.pop()
  if (timestamp) {
    // remove date
    pathParts.pop()
    const serial = pathParts.join('/')
    const formattedPrefix = formatPrefix(serial, timestamp)
    // Round to 10 minutes to get prefix
    return formattedPrefix.substring(0, formattedPrefix.length - 10)
  }

  return pathname
}

export function getDateTimeFromURL() {
  const pathParts = window.location.pathname.split('/')
  const dateString = pathParts[3]

  if (pathParts.length > 3) {
    const dateISO = dateString.replace('.ogg', '')
    const date = DateTime.fromISO(dateISO).setZone('Europe/Paris')
    if (date.isValid) return date
  }

  if (dateString) console.log(`Heure invalide : ${dateString}`)
  return undefined
}

type Props = {
  bucket: BucketName
  bucketPrefix: string
  metricsConfig: MetricsConfig
}

export function Explorer({ bucket, bucketPrefix, metricsConfig }: Props) {
  const [serial, date] = useMemo(() => {
    const parts = bucketPrefix.split('/')
    const serial = parts[0] // bucketPrefix is supposed to starts with a serial
    const date = parts.slice(parts.length - 1)[0] // bucketPrefix is supposed to ends with a date
    return [serial, date]
  }, [bucketPrefix])

  const { s3Client, bucketName, getS3Url } = useS3Context()

  const { predictions, loadPrefixPredictions } = usePredictions(
    metricsConfig,
    serial,
    bucket,
    bucketPrefix,
  )

  const [start, end] = useMemo(() => {
    const startDate = BUCKETS_CONFIG_MAP[bucket].startDate(date)
    const endDate = startDate.plus({ day: 1 })

    return [startDate.valueOf(), endDate.valueOf()]
  }, [bucket, date])

  const bucketPrefixes: string[] = useMemo(() => {
    let currentDate = BUCKETS_CONFIG_MAP[bucket].startDate(date)

    const prefixes = []
    for (let i = 0; i < ms(24, 'hours'); i += BUCKET_DURATION) {
      const prefix = `${bucketPrefix}/${currentDate.toISO().slice(0, 15)}` // Works because BucketDuration is 10 !
      prefixes.push(prefix)
      currentDate = currentDate.plus({ minute: 10 })
    }
    return prefixes
  }, [bucket, bucketPrefix, date])

  const [selectedPrefix, setSelectedPrefix] = useState<string | null>(null)
  const [preloadBuckets, setPreloadBuckets] = useState<PreloadType | null>(null)
  const [bucketPreloadIndex, setBucketPreloadIndex] = useState<number | null>(
    null,
  )

  const [prefixDataMap, setPrefixDataMap] = useState<PrefixDataMap>({})

  const graphCacheRef = useRef<GraphCache>(
    new Map<Prefix, { counts: number[]; sums: number[] }>(),
  )

  const [contrast, setContrast] = useState(50)

  useEffect(() => {
    setSelectedPrefix(null)
  }, [bucketPrefix])

  useEffect(() => {
    setPrefixDataMap(defaultPrefixDataMap(bucketPrefixes))
  }, [serial, bucketPrefixes])

  // Load all files from s3 for this prefix
  const forceLoadBucket = useCallback(
    async (prefix: string) => {
      try {
        const data = await fetchData(s3Client, prefix, { bucketName })

        const timestamp = DateTime.fromISO(prefix.split('/').pop() + '0')
          .setZone('Europe/Paris', { keepLocalTime: true })
          .valueOf()
        const start =
          data.length !== 0
            ? Math.min(timestamp, data[0].endTimestamp - SOUND_DURATION)
            : timestamp
        const end = timestamp + BUCKET_DURATION

        await loadPrefixPredictions(prefix, start + SOUND_DURATION, end) // Add SOUND_DURATION to start to avoid fetching predictions of last sound of previous bucket

        setPrefixDataMap((prefixDataMap) => ({
          ...prefixDataMap,
          [prefix]: { data, start, end },
        }))
      } catch {
        onError
      }
    },
    [bucketName, loadPrefixPredictions, s3Client],
  )

  // Load all files from s3 for this prefix
  const loadBucket = useCallback(
    async (prefix: string) => {
      // Ignore if already loaded
      if (prefixDataMap[prefix] !== undefined) return

      await forceLoadBucket(prefix)
    },
    [forceLoadBucket, prefixDataMap],
  )

  // If bucket is current time, reload data periodically
  useEffect(() => {
    // Use some margin to account for possible local date difference
    const range = 15 // must be greater than BUCKET_DURATION

    const now = DateTime.local().setZone('Europe/Paris')
    const start = now.minus({ minutes: range }).toISO()
    const end = now.plus({ minutes: range }).toISO()

    if (
      selectedPrefix !== null &&
      start < selectedPrefix &&
      selectedPrefix < end
    ) {
      const intervalId = setInterval(
        () => forceLoadBucket(selectedPrefix),
        SOUND_DURATION,
      )
      return () => clearInterval(intervalId)
    }
    return () => {}
  }, [selectedPrefix, forceLoadBucket])

  // Preload bucket predictions
  useEffect(() => {
    // preloadBuckets disabled
    if (!preloadBuckets) {
      setBucketPreloadIndex(null)
      return
    }

    // preloadBuckets enabled
    if (bucketPreloadIndex === null) {
      setBucketPreloadIndex(PRELOAD_SHIFTS[preloadBuckets].startIndex)
      return
    }

    // preloadBuckets in progress
    const prefix = bucketPrefixes[bucketPreloadIndex]
    loadBucket(prefix).then(() => {
      if (bucketPreloadIndex < PRELOAD_SHIFTS[preloadBuckets].endIndex)
        setBucketPreloadIndex(bucketPreloadIndex + 1)
      else setPreloadBuckets(null) // All buckets are loaded
    })
  }, [bucketPreloadIndex, preloadBuckets, bucketPrefixes, loadBucket])

  // Load data on selected prefix change
  useEffect(() => {
    if (selectedPrefix) loadBucket(selectedPrefix)
  }, [selectedPrefix, loadBucket])

  useEffect(() => {
    async function checkSoundFromURLExists() {
      const dateTime = getDateTimeFromURL()

      if (dateTime) {
        const prefix = getRoundedPrefixFromURL()
        const data = await fetchData(s3Client, prefix, { bucketName })
        const timestamp = dateTime
        const index = data.findIndex(({ endTimestamp }) =>
          bucketName === 'oso-resp-sounds'
            ? timestamp.toMillis() ===
              BUCKETS_CONFIG_MAP[bucketName]
                .startDate(DateTime.fromMillis(endTimestamp).toISO())
                .toMillis()
            : endTimestamp === timestamp.valueOf(),
        )

        // Show Player only if sound exists
        if (index >= 0) setSelectedPrefix(prefix)
      }
    }
    checkSoundFromURLExists()
  }, [bucketName, date, s3Client, serial])

  const selectedPrefixData = selectedPrefix
    ? prefixDataMap[selectedPrefix]
    : undefined

  return (
    <div className="relative flex flex-col gap-4 p-4">
      <div className="flex flex-row flex-wrap justify-center gap-2">
        {Object.entries(metricsConfig).map(([metricKey, metricConfig]) => {
          return (
            <div key={metricKey} className="flex flex-col text-white">
              <span>{metricConfig.label}</span>
              <div
                className="h-1 w-full"
                style={{
                  backgroundColor: metricConfig.color,
                }}
              />
            </div>
          )
        })}
      </div>
      {bucket === 'oso-resp-sounds' && (
        <Centered>
          <b className="text-red-500">
            Attention : les dates et heures des enregistrements ne sont pas
            correctes
          </b>
        </Centered>
      )}
      <Buckets
        metricsConfig={metricsConfig}
        bucketPrefixes={bucketPrefixes}
        prefixDataMap={Object.fromEntries(
          Object.entries(prefixDataMap).filter(([key]) =>
            key.startsWith(bucketPrefix),
          ),
        )}
        predictions={predictions}
        selectedPrefix={selectedPrefix}
        onBucketClick={(prefix) => setSelectedPrefix(prefix)}
      />
      <TimeMarks start={start} end={end} />
      <div className="flex flex-row justify-end gap-2 px-2">
        <input
          type="range"
          className="accent-sky-700"
          value={contrast}
          onChange={(e) => setContrast(parseInt(e.target.value))}
          title="Réglage de la sensibilité"
        />
        <Button
          title="Précharger les données entre 20h et 08h"
          onClick={() => setPreloadBuckets(!preloadBuckets ? 'night' : null)}
        >
          {preloadBuckets === 'night' ? 'En cours...' : 'Précharger la nuit'}
        </Button>
        <Button
          title="Précharger les données sur l'ensemble de la journée"
          onClick={() => setPreloadBuckets(!preloadBuckets ? 'all' : null)}
        >
          {preloadBuckets === 'all' ? 'En cours...' : 'Précharger tout'}
        </Button>
      </div>
      {selectedPrefix ? (
        selectedPrefixData === undefined ||
        predictions[selectedPrefix] === undefined ? (
          <Centered>Chargement...</Centered>
        ) : selectedPrefixData.data.length === 0 &&
          Object.keys(predictions[selectedPrefix]).length === 0 ? (
          <Centered>{`Aucun son entre ${formatMsTime(
            selectedPrefixData.start,
          )} et ${formatMsTime(selectedPrefixData.end)}`}</Centered>
        ) : (
          <Player
            getS3Url={getS3Url}
            serial={serial}
            prefix={selectedPrefix}
            data={selectedPrefixData.data}
            start={selectedPrefixData.start}
            end={selectedPrefixData.end}
            contrast={contrast}
            graphCache={graphCacheRef.current}
            predictions={predictions[selectedPrefix]}
            metricsConfig={metricsConfig}
          ></Player>
        )
      ) : (
        <Centered>Sélectionnez un intervalle</Centered>
      )}
    </div>
  )
}
