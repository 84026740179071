import { BucketName } from 'common/types'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useEffect, useMemo, useState } from 'react'
import {
  GetMetricsNamesParams,
  GetMetricsNamesResponseData,
  MetricKey,
} from 'shared/types/victoria'
import {
  MetricsConfig,
  defaultMetrics,
  metricDisplayName,
} from 'shared/utils/web/metrics'
import { BucketDialog } from './BucketDialog'
import { Explorer } from './Explorer'
import { Path, calculateTimestampRange } from './Path'
import { S3Provider } from './S3Provider'
import { StandalonePath } from './StandalonePath'
import { DropdownCheckboxes } from './components/DropdownCheckboxes'
import { app } from './firebase'

const functions = getFunctions(app, 'europe-west1')
const getMetricsNames = httpsCallable<
  GetMetricsNamesParams,
  GetMetricsNamesResponseData
>(functions, 'getMetricsNames')

const selectedPredictionsStorageKey = 'selectedPredictions'
const SELECTED_BUCKET_KEY = 'selectedBucket'

export function Main() {
  const [metricsKeys, setMetricsKeys] = useState<MetricKey[]>([])
  const [bucketPrefix, setBucketPrefix] = useState<string>()
  const [bucket, setBucket] = useState<BucketName>(() => {
    const selectedBucket = localStorage.getItem(SELECTED_BUCKET_KEY)
    return selectedBucket ? JSON.parse(selectedBucket) : 'oso-sounds'
  })

  function changeBucket(value: BucketName) {
    window.history.replaceState(null, '', '/')
    localStorage.setItem(SELECTED_BUCKET_KEY, JSON.stringify(value))
    setBucket(value)
  }

  useEffect(() => {
    if (!bucketPrefix) return

    const parts = bucketPrefix.split('/')
    const serial = parts[0]
    const date = parts.slice(parts.length - 1)[0]

    const { start: validatedStartTs, end: validatedEndTs } =
      calculateTimestampRange(date)

    getMetricsNames({
      serial,
      start: validatedStartTs,
      end: validatedEndTs,
    })
      .then((result) => {
        setMetricsKeys(result.data)
      })
      .catch((error) => {
        console.error('Error fetching labels:', error)
      })
  }, [bucketPrefix])

  const [selectedMetricsKeys, setSelectedMetricsKeys] = useState(() => {
    const selectedPredictionsStorage = localStorage.getItem(
      selectedPredictionsStorageKey,
    )
    return selectedPredictionsStorage
      ? JSON.parse(selectedPredictionsStorage)
      : Object.keys(defaultMetrics)
  })

  const selectedMetricsConfig = useMemo<MetricsConfig>(() => {
    const defaultColors = Object.values(defaultMetrics).map(
      ({ color }) => color,
    )
    let index = 0
    const selectedMetricsConfig: MetricsConfig = {}
    for (const metricKey of selectedMetricsKeys) {
      if (defaultMetrics[metricKey] !== undefined) {
        selectedMetricsConfig[metricKey] = defaultMetrics[metricKey]
      } else {
        selectedMetricsConfig[metricKey] = {
          label: metricDisplayName(metricKey),
          color: defaultColors[index % defaultColors.length],
        }
        index += 1
      }
    }
    return selectedMetricsConfig
  }, [selectedMetricsKeys])

  return (
    <div className="flex flex-col gap-8">
      <BucketDialog bucket={bucket} handleValidate={changeBucket} />
      <S3Provider bucketName={bucket}>
        <div className="flex flex-row justify-around p-4 shadow-lg">
          {bucket === 'oso-sounds' ? (
            <Path onChange={setBucketPrefix}></Path>
          ) : (
            <StandalonePath onChange={setBucketPrefix}></StandalonePath>
          )}

          <DropdownCheckboxes
            options={metricsKeys}
            value={selectedMetricsKeys}
            onValidation={(selectedMetrics) => {
              setSelectedMetricsKeys(selectedMetrics)
              localStorage.setItem(
                selectedPredictionsStorageKey,
                JSON.stringify(selectedMetrics),
              )
            }}
            resetValue={Object.keys(defaultMetrics)}
            disabled={!bucketPrefix}
          >
            Prédictions affichées
          </DropdownCheckboxes>
        </div>
        {bucketPrefix && (
          <Explorer
            bucket={bucket}
            bucketPrefix={bucketPrefix}
            metricsConfig={selectedMetricsConfig}
          ></Explorer>
        )}
      </S3Provider>
    </div>
  )
}
